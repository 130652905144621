import { Input, Modal } from 'antd';
import { Select } from 'antd';
import { Merchant } from 'types';
import IBAN from 'iban';

import { upsertMerchant } from 'features/merchant';

import styled from 'styled-components';

/**
 * Imports
 */
import * as React from 'react';

const { Option } = Select;

const ErrorDiv = styled.h3`
  color: red;
`;

const Label = styled.div`
  margin-top: 5px;
`;
const FirstLabel = styled.div`
  margin-top: 0px;
`;
const ModalRoot = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;
const ModalLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 20px;
`;
const ModalRight = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 20px;
`;

/**
 * Types
 */
type MerchantModalProps = {
  show: boolean;
  closeModal: () => void;
  merchant: Merchant;
  fetchMerchant: () => Promise<void>;
};

/**
 * MerchantDetails component
 */
const UpdateMerchantModal: React.FC<MerchantModalProps> = ({
  fetchMerchant,
  merchant,
  show,
  closeModal,
}) => {
  const [confirmLoading, setConfirmLoading] = React.useState<boolean>(false);
  const [clearhausMerchantId, setClearhausMerchantId] = React.useState(
    merchant.clearhausMerchantId,
  );
  const [merchantStoreName, setMerchantStoreName] = React.useState(
    merchant.merchantStoreName,
  );
  const [merchantName, setMerchantName] = React.useState(merchant.merchantName);
  const [registrationNumber, setRegistrationNumber] = React.useState(
    merchant.registrationNumber,
  );
  const [descriptor, setDescriptor] = React.useState(merchant.descriptor);
  const [iban, setIban] = React.useState(merchant.iban);
  const [bic, setBic] = React.useState(merchant.bic);
  const [addressLine1, setAddressLine1] = React.useState(merchant.addressLine1);
  const [addressLine2, setAddressLine2] = React.useState(merchant.addressLine2);
  const [city, setCity] = React.useState(merchant.city);
  const [zip, setZip] = React.useState(merchant.zip);
  const [countryCode, setCountryCode] = React.useState(merchant.country);
  const [timezone, setTimezone] = React.useState(merchant.timezone);
  const [name, setName] = React.useState(merchant.contactName);
  const [email, setEmail] = React.useState(merchant.contactEmail);
  const [phone, setPhone] = React.useState(merchant.contactPhone);
  const [mcc, setMcc] = React.useState(merchant.mcc);
  const [currencyCode, setCurrencyCode] = React.useState(merchant.currency);
  const [merchantContract, setMerchantContract] = React.useState(
    merchant.merchantContract,
  );
  const [error, setError] = React.useState<string | null>(null);

  const _handleOk = async () => {
    setConfirmLoading(true);
    const allSetters = [
      clearhausMerchantId,
      merchantStoreName,
      merchantName,
      registrationNumber,
      descriptor,
      iban,
      addressLine1,
      city,
      zip,
      countryCode,
      timezone,
      name,
      email,
      phone,
    ];
    const isValidIban = IBAN.isValid(iban);
    const zipIsValid = zip.length <= 6;
    if (zipIsValid && isValidIban && allSetters.every((a) => a !== '')) {
      const done = await upsertMerchant(
        'update',
        merchant.merchantId,
        clearhausMerchantId,
        merchantStoreName,
        merchantName,
        registrationNumber,
        descriptor,
        iban,
        bic,
        addressLine1,
        addressLine2,
        city,
        zip,
        countryCode,
        timezone,
        name,
        email,
        phone,
        mcc,
        currencyCode,
        merchantContract,
      );

      if (done) {
        await fetchMerchant();
        _closeModal();
      } else {
        setConfirmLoading(false);
        setError('contact support');
      }
    } else {
      if (!isValidIban) {
        setError('Error - invalid IBAN');
      } else if (!zipIsValid) {
        setError('Error - to long zip');
      } else {
        setError('Please fill all fields');
      }
      setTimeout(() => {
        setConfirmLoading(false);
        setError(null);
      }, 2000);
    }
  };

  const _closeModal = () => {
    setError(null);
    closeModal();
  };

  return (
    <Modal
      title="Merchant"
      width={1024}
      visible={show}
      okText="Update merchant"
      onOk={() => _handleOk()}
      onCancel={_closeModal}
      confirmLoading={confirmLoading}
    >
      <ModalRoot>
        <ModalLeft>
          <Label>Clearhaus Merchant Id</Label>
          <Input
            placeholder="Clearhaus id"
            value={clearhausMerchantId}
            onChange={(event) => setClearhausMerchantId(event.target.value)}
          />
          <Label>Merchant Store Name</Label>
          <Input
            placeholder="Merchant store name"
            value={merchantStoreName}
            onChange={(event) => setMerchantStoreName(event.target.value)}
          />
          <Label>Merchant Name</Label>
          <Input
            placeholder="Merchant name"
            value={merchantName}
            onChange={(event) => setMerchantName(event.target.value)}
          />
          <Label>Registration number</Label>
          <Input
            placeholder="Registration Number"
            value={registrationNumber}
            onChange={(event) => setRegistrationNumber(event.target.value)}
          />
          <Label>Descriptor</Label>
          <Input
            placeholder="Descriptor"
            value={descriptor}
            onChange={(event) => setDescriptor(event.target.value)}
          />
          <Label>IBAN</Label>
          <Input
            placeholder="iban"
            value={iban}
            onChange={(event) => setIban(event.target.value)}
          />
          <Label>BIC</Label>
          <Input
            placeholder="bic"
            value={bic}
            onChange={(event) => setBic(event.target.value)}
          />
          <Label>MCC code</Label>
          <Select
            defaultValue={mcc}
            onChange={(value) => setMcc(value)}
            style={{ width: 200 }}
          >
            <Option value="7995">7995 - Gambling</Option>
            <Option value="4789">4789 - Transportation Services</Option>
            <Option value="7523">
              7523 - Automobile Parking Lots and Garages
            </Option>
            <Option value="5046">5046 - Commercial Equipment</Option>
            <Option value="8299">
              8299 - Schools and Educational Services
            </Option>
            <Option value="5541">5541 - Service Stations</Option>
            <Option value="5814">5814 - FAST FOOD RESTAURANTS</Option>
            <Option value="4814">4814 - TELECOMMUNCATIONS</Option>
            <Option value="4816">4816 - COMPUTER NETWORK/INFO SVCS </Option>
            <Option value="5912">5912 - Drug Stores & Pharmacies</Option>
            <Option value="7994">7994 - VIDEO GAME ARCADES</Option>
          </Select>
          <Label>Currency code</Label>
          <Select
            defaultValue={currencyCode}
            onChange={(value) => setCurrencyCode(value)}
            style={{ width: 120 }}
          >
            <Option value="GBP">GBP</Option>
            <Option value="EUR">EUR</Option>
            <Option value="DKK">DKK</Option>
            <Option value="NOK">NOK</Option>
            <Option value="CZK">CZK</Option>
          </Select>
          <Label>Merchant Contract</Label>
          <Select
            disabled
            defaultValue={merchantContract}
            onChange={(value) => setMerchantContract(value)}
            style={{ width: 200 }}
          >
            <Option value="gauselmann">Gauselmann</Option>
            <Option value="gauselmann_referal">Gauselmann Referal</Option>
            <Option value="novomatic">Novomatic</Option>
            <Option value="novomatic_referal">Novomatic Referal</Option>
            <Option value="vibrant_test">Vibrant test</Option>
            <Option value="link4pay_test">Link4Pay test</Option>
            <Option value="ucc_coop">UCC Coop Norway</Option>
            <Option value="ucc_uk">UCC UK</Option>
            <Option value="ucc_ie">UCC IE</Option>
            <Option value="ucc_nl">UCC NL</Option>
          </Select>
          {error && <ErrorDiv>{error}</ErrorDiv>}
        </ModalLeft>
        <ModalRight>
          <h3>Address</h3>
          <FirstLabel>Addressline 1</FirstLabel>
          <Input
            placeholder="addressLine1"
            value={addressLine1}
            onChange={(event) => setAddressLine1(event.target.value)}
          />
          <Label>Addressline 2</Label>
          <Input
            placeholder="addressLine2"
            value={addressLine2}
            onChange={(event) => setAddressLine2(event.target.value)}
          />
          <Label>City (remember to create on Ocean before)</Label>
          <Input
            placeholder="city"
            value={city}
            onChange={(event) => setCity(event.target.value)}
          />
          <Label>Zip</Label>
          <Input
            placeholder="zip"
            value={zip}
            onChange={(event) => setZip(event.target.value)}
          />
          <Label>Timezone</Label>
          <Select
            defaultValue={timezone}
            onChange={(value) => setTimezone(value)}
            style={{ width: 200 }}
          >
            <Option value="Europe/London">Europe/London</Option>
            <Option value="Europe/Copenhagen">Europe/Copenhagen</Option>
            <Option value="Europe/Vienna">Europe/Vienna</Option>
            <Option value="Europe/EasternTime">Europe/EasternTime</Option>
          </Select>
          <Label>Country Code</Label>
          <Select
            defaultValue={countryCode}
            onChange={(value) => setCountryCode(value)}
            style={{ width: 200 }}
          >
            <Option value="GB">Great Britain</Option>
            <Option value="DK">Denmark</Option>
            <Option value="CY">Cyprus</Option>
            <Option value="NO">Norway</Option>
            <Option value="NL">Netherland</Option>
            <Option value="ES">Spain</Option>
            <Option value="IE">Ireland</Option>
            <Option value="CH">Switzerland</Option>
            <Option value="BE">Belgium</Option>
            <Option value="CZ">Czech</Option>
            <Option value="AT">Austria</Option>
            <Option value="GR">Greece</Option>
          </Select>
          <br />
          <h3>Contact</h3>
          <FirstLabel>Name</FirstLabel>
          <Input
            placeholder="name"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
          <Label>Email</Label>
          <Input
            placeholder="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <Label>Phone</Label>
          <Input
            placeholder="phone"
            value={phone}
            onChange={(event) => setPhone(event.target.value)}
          />
        </ModalRight>
      </ModalRoot>
    </Modal>
  );
};

/**
 * Exports
 */
export { UpdateMerchantModal };
